# Display a cofirm prompt before submitting a form (e.g. when deleting)
# @param string message The message to confirm (e.g. Are you sure...?)
$.fn.confirmSubmit = (message) ->
  if confirm(message)
    $(this).submit()

# To use, simply pass flash_message='...' with the view/ redirect
# To make the message stay on the screen, pass flash_message_important=true

# our custom alert flash message box
if $(".alert").length
    $(".alert")
      .not(".alert-important")
      .delay(3000)
      .slideUp(300)


showHidePasswordLogin = ->
  login = document.getElementById("password_login");
  if (login.type == "password")
    event.target.classList.add('fa-eye-slash');
    event.target.classList.remove('fa-eye');
    login.type = "text";
  else
    event.target.classList.remove('fa-eye-slash');
    event.target.classList.add('fa-eye');
    login.type = "password";

showHidePasswordRegister = ->
  register = document.getElementById("password_register");
  if (register.type == "password")
    event.target.classList.add('fa-eye-slash');
    event.target.classList.remove('fa-eye');
    register.type = "text";
  else
    event.target.classList.remove('fa-eye-slash');
    event.target.classList.add('fa-eye');
    register.type = "password";

if ($('.toggle-password-register').length)
    $('.toggle-password-register').click(showHidePasswordRegister)
if ($('.toggle-password-login').length)
    $('.toggle-password-login').click(showHidePasswordLogin)
